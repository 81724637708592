<template>
  <div class="content-left-layout">
    <div class="content-left-layout">
      <div class="content-left-layout__content">
        <div class="content-left-layout__left">
          <slot />
        </div>
        <div class="content-left-layout__right">
          <slot name="right" />
        </div>
      </div>

      <app-toast-wrapper />
    </div>
  </div>
</template>

<style lang="scss">
  .content-left {
    display: flex;
    width: 100%;
  }

  .content-left-layout {
    position: relative;
    display: flex;
    min-height: 100dvh;
    width: 100%;
    overflow: hidden;
  }

  .content-left-layout__content {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    @include breakpoint(desktop) {
      flex-direction: row;
    }
  }

  .content-left-layout__left {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;

    @include breakpoint(desktop) {
      padding: 32px 256px 32px 32px;
    }
  }

  .content-left-layout__right {
    display: none;

    @include breakpoint(desktop) {
      display: block;
      position: relative;
      background: var(--color-primary);
      width: 40%;
      background: none;
    }
  }

  .content-left-layout__right:after {
    @include breakpoint(desktop) {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 100vw;
      background: var(--color-primary);
      background-image: url('/images/alt-hero.jpg');
      background-size: cover;
      clip-path: polygon(100px 0%, 100% 0%, 1000% 100%, 0% 100%)
    }
  }
</style>
